<!-- eslint-disable no-restricted-syntax -->
<template>
  <div>
    <b-row>

      <!-- Upload arquivos -->

      <b-col cols="12">
        <b-input-group>
          <b-overlay
            :show="show"
            spinner-variant="primary"
            spinner-type="border"
            spinner-large
            rounded="lg"
          >
            <b-form-file
              ref="fileInput"
              v-model="selectedFiles"
              multiple
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              size="lg"
              accept=".txt, .csv"
            />
          </b-overlay>
        </b-input-group>
      </b-col>

      <b-col>
        <div
          cols=""
          class="mt-1"
        >
          <b-button
            @click="UploadFiles()"
          >
            Submit
          </b-button>
        </div>
      </b-col>

    </b-row>
    <validate-logon-keycloak ref="validatelogon" />

  </div>
</template>

<script>
import axios from 'axios'
import ValidateLogonKeycloak from '@/views/reusable-components/ValidateLogonKeycloak.vue'
import {
  BFormFile, BRow, BCol, BInputGroup, BButton, BOverlay,
} from 'bootstrap-vue'

export default {
  name: 'FileUpload',
  components: {
    BOverlay,
    BButton,
    BInputGroup,
    BFormFile,
    BRow,
    BCol,
    ValidateLogonKeycloak,

  },
  data() {
    return {
      show: false,
      selectedFiles: [],
      filenames: [],
      browseTeste: {
        id: 'browseTeste',
      },
    }
  },

  mounted() { // Mounted has to be used when using a Child and trigger after the import
    this.validateUserLogon()
    this.setUser()
  },

  methods: {
    validateUserLogon() {
      this.$refs.validatelogon.validateLogon()
    },
    setUser() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      this.$parent.$parent.$parent.$refs.adenav.username = userData.fullName
    },
    toast(toaster, append = false, variant, message, title) {
      this.$bvToast.toast(message, {
        title,
        toaster,
        solid: true,
        appendToast: append,
        variant,
      })
    },
    async UploadFiles() {
      this.show = true // loading page b-overlay
      const formData = new FormData()

      const fileInputElement = this.$refs.fileInput.$el.querySelector('input[type="file"]')

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < fileInputElement.files.length; i++) {
        formData.append('files', fileInputElement.files[i])
      }

      try {
        const cnpjSelected = this.$parent.$refs.cboxclient.selected
        if (!cnpjSelected) {
          throw new Error('Selecione o CNPJ')
        }
        const response = await axios.post(`/fileupload/uploadfiles/${cnpjSelected}`, formData)
        this.$parent.filesTable()
        console.log(response.data)
        // deixar o campo em branco após ter adicionado
        this.selectedFiles = ''
      } catch (error) {
        this.toast('b-toaster-top-right', true, 'danger', ` ${error}`)
        console.error(error)
      }
      this.show = false
    },

  },

}
</script>
